import { useEffect, useState } from 'react'
import { Button, Space, Image, Row, Form, Col } from 'antd'
import styled from 'styled-components'
import { ColumnsType } from 'antd/es/table'
import { PortalTable } from 'components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ListItemProductType } from 'services/portal_apis/product_apis'
import { product } from 'services'
import ProductManagerModal from './productEditModal'
import { MainLayout } from 'layouts/mainLayout'

const ProductManager = () => {
    const [itemProduct, setItemProduct] = useState<ListItemProductType[]>()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [form] = Form.useForm()

    useEffect(() => {
        fetchData().then()
    }, [])

    async function fetchData() {
        const response = await product()
        if (response) {
            setItemProduct(response.results)
        }
    }

    function submit() {
        // console.log('submit')
    }

    const productManager: ColumnsType<ListItemProductType> = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any, index: number) => {
                ++index
                return index
            },
        },
        {
            title: 'รูป',
            dataIndex: '',
            key: 'Image',
            render: ({ productImageUrl }) => {
                return (
                    <Row>
                        <Image width={60} height={60} src={productImageUrl} />
                    </Row>
                )
            },
        },
        { title: 'รหัส', dataIndex: 'sku' },
        { title: 'ชื่อ', dataIndex: 'name' },
        { title: 'รายละเอียด', dataIndex: 'description' },
        { title: 'ราคา', dataIndex: 'price' },
        {
            title: 'action',
            dataIndex: 'operation',
            key: 'id',
            align: 'center',
            width: '4%',
            render: (id, record, index) => {
                return (
                    <Space size="middle" className={'btn-right'}>
                        <Button
                            className={'button-warning'}
                            onClick={() => {
                                setIsModalOpen(true)
                            }}>
                            <EditOutlined />
                        </Button>
                        <Button
                            className={'button-danger'}
                            onClick={() => {
                                console.log('test')
                            }}>
                            <DeleteOutlined />
                        </Button>
                    </Space>
                )
            },
        },
    ]
    return (
        <MainLayout pageName="Product Manager">
            <Row>
                <Col md={24} xs={24}>
                    <PortalTable columns={productManager} data={itemProduct} />
                </Col>
            </Row>
            <ProductManagerModal
                form={form}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                formSubmit={submit}
            />
        </MainLayout>
    )
}

export default ProductManager
