import axios from 'axios'
import { API_URL } from '../config'

async function uploadPdf(platform: 'shopee' | 'lazada', formData: FormData) {
    formData.append('platform', platform)
    return axios
        .post(`${API_URL}/portal/merged-pdf`, formData, { timeout: 300000 })
        .then((resp) => resp.data)
}

async function listPdf(platform: 'shopee' | 'lazada') {
    return axios
        .get(`${API_URL}/portal/list-pdf`, {
            params: {
                platform,
            },
        })
        .then((resp) => resp.data)
}
async function downloadPdf(platform: 'shopee' | 'lazada', deletedPdf: string[]) {
    return axios
        .post(
            `${API_URL}/portal/download-pdf`,
            {
                deleted_pdf: deletedPdf,
                platform: platform,
            },
            {
                responseType: 'blob',
                timeout: 300000,
            }
        )
        .then((resp) => resp.data)
}

export { uploadPdf, listPdf, downloadPdf }
