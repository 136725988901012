import { MainLayout } from 'layouts/mainLayout'
import { Button, Col, Form, Input, Pagination, PaginationProps, Row } from 'antd'
import { SearchBox } from 'components/search-box'
import { useEffect, useState } from 'react'
import { CargoType, ListItemCargoType, searchProduct } from 'services'
import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import { PortalTable } from 'components'
import styled from 'styled-components'

const SearchCargoTracking = () => {
    const [form] = Form.useForm()
    const [itemCargo, setItemCargo] = useState<CargoType>()
    const [itemListCargo, setItemListCargo] = useState<ListItemCargoType[]>([])

    const cargoTrackingColumns: ColumnsType<ListItemCargoType> = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (_, __, index: number) => {
                ++index
                return index
            },
        },
        { title: 'รหัส', dataIndex: 'tracking_no' },
        { title: 'วันที่สินค้าถึง', dataIndex: 'date_arrival' },
        { title: 'บันทึกข้อความ', dataIndex: 'note' },
    ]

    useEffect(() => {
        loadCargoList(null, null)
    }, [])

    async function loadCargoList(tracking_no: string | null, page: number | null) {
        const response = await searchProduct(tracking_no, page)

        if (response) {
            const listItemsFilter = _.filter(response.results.data, (item) => !item.is_checked)
            setItemListCargo(listItemsFilter)
            setItemCargo(response)
        }
    }

    async function onSubmit() {
        const tracking_no = form.getFieldValue('tracking_no')
        loadCargoList(tracking_no, 1)
    }

    const onChange: PaginationProps['onChange'] = (pageNumber) => {
        const tracking_no = form.getFieldValue('tracking_no')
        loadCargoList(tracking_no, pageNumber)
    }

    return (
        <MainLayout pageName="ค้นหาแทร็กจากคาร์โก้">
            <SearchCargoContainer>
                <Row gutter={[16, 16]}>
                    <Col md={24} xs={24}>
                        <SearchBox onSubmit={onSubmit} form={form}>
                            <Form.Item name="tracking_no">
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <div style={{ textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit">
                                        ค้นหา
                                    </Button>
                                </div>
                            </Form.Item>
                        </SearchBox>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} xs={24}>
                        <TableContainer>
                            <PortalTable columns={cargoTrackingColumns} data={itemListCargo} />
                        </TableContainer>
                    </Col>
                </Row>
                {itemCargo && (
                    <PaginationContainer>
                        <PaginationBox
                            defaultPageSize={20}
                            showSizeChanger={false}
                            defaultCurrent={1}
                            total={itemCargo.count ?? 0}
                            onChange={onChange}
                        />
                    </PaginationContainer>
                )}
            </SearchCargoContainer>
        </MainLayout>
    )
}
export default SearchCargoTracking

const TableContainer = styled.div`
    margin-top: 10px;
`

const SearchCargoContainer = styled.div``

const PaginationContainer = styled.div`
    width: 80%;
    position: absolute;
    bottom: 0;
    margin-bottom: 24px;
    padding-top: 8px;
    background: #fff;
    height: 42px;
`

const PaginationBox = styled(Pagination)`
    position: absolute;
    right: 0;
`
