import { notification } from 'antd'
export function useNotification() {
    const openNotification = (
        message: string,
        type: 'success' | 'error' | 'warning' | 'info' | 'open' = 'open',
        delay = 0
    ) => {
        if (delay > 0) return setTimeout(() => notification.open({ message }), delay)
        notification[type]({
            message,
        })
    }
    return { openNotification }
}
