import axios from 'axios'
import { refresh } from './auth_api'
import { setAuthorizationHeader } from './axios_config'
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 3 })

// Create a variable to store the original request
let isRefreshing = false
let refreshSubscribers: ((token: string) => void)[] = []

// Function to add requests to the refreshSubscribers array
function subscribeTokenRefresh(callback: (token: string) => void) {
    refreshSubscribers.push(callback)
}

// Function to refresh the token and execute the queued requests
async function onTokenRefreshed(token: string) {
    refreshSubscribers.map((callback) => callback(token))
    refreshSubscribers = []
}

axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    async function (error) {
        // const isRefreshApi = error.config.url?.includes('login/refresh')
        const isLoginApi = error.config.url?.includes('login')
        if (!isLoginApi && error.response.status === 401) {
            const refreshToken = localStorage.getItem('token') || undefined
            if (refreshToken) {
                if (!isRefreshing) {
                    isRefreshing = true
                    const response = await refresh(refreshToken)
                    if (!response) {
                        return window.location.replace('/')
                    }
                    setAuthorizationHeader(response.access)
                    isRefreshing = false
                    onTokenRefreshed(response.access) // Call onTokenRefreshed with the new access token
                    return axios(error.config) // Retry the original request
                } else {
                    // If a token refresh is already in progress, add the request to the refreshSubscribers array
                    return new Promise((resolve) => {
                        subscribeTokenRefresh((token: string) => {
                            error.config.headers.Authorization = 'Bearer ' + token
                            resolve(axios(error.config))
                        })
                    })
                }
            }
        }
        return Promise.reject(error)
    }
)
