import axios from 'axios'
import { API_URL } from './config'

export type JwtToken = {
    refresh: string
    access: string
}

async function login(username: string, password: string): Promise<JwtToken> {
    return axios.post(`${API_URL}/auth/login`, { username, password }).then((resp) => resp.data)
}

async function refresh(refresh: string): Promise<JwtToken> {
    return axios
        .post(`${API_URL}/auth/login/refresh`, { refresh })
        .then((resp) => resp.data)
        .catch((err) => {
            // implement error later
            console.log({ err })
        })
}

export { login, refresh }
