import React from 'react'
import { Form, FormInstance } from 'antd'
import styled from 'styled-components'
type SearchBoxProps = {
    children: React.ReactNode
    onSubmit: () => void
    form: FormInstance<any>
}

export const SearchBox = ({ children, onSubmit, form }: SearchBoxProps) => {
    return (
        <SearchBoxContain>
            <a>ตัวกรอง</a>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                // style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                form={form}
                layout="inline"
                autoComplete="off">
                {children}
            </Form>
        </SearchBoxContain>
    )
}

const SearchBoxContain = styled.div`
    border: solid 1.5px #d6d6d6;
    padding: 3rem;
    position: relative;
    a {
        color: black;
        font-weight: bold;
        position: absolute;
        top: -10px;
        background-color: white;
        padding: 0 10px;
    }
`
