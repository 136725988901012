import React, { useState } from 'react'
import {
    DesktopOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    FilePdfOutlined,
    ShopFilled,
    EnvironmentFilled,
    LogoutOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

const { Sider } = Layout
const { Item, SubMenu } = Menu
interface MenuItem {
    key: string
    label: React.ReactNode
    icon?: React.ReactNode
    link?: string
    children?: MenuItem[]
}
const items: MenuItem[] = [
    // {
    //     key: '1',
    //     label: 'Product Manager',
    //     icon: <PieChartOutlined />,
    //     link: '/product-manager',
    // },
    {
        key: 'check-in',
        label: 'เช็คอิน',
        icon: <EnvironmentFilled />,
        link: '/check-in',
    },
    {
        key: 'print-tracking',
        label: 'ปริ๊นแทร็ก',
        icon: <FilePdfOutlined />,
        children: [
            {
                key: 'print-shopee',
                label: 'Shopee',
                link: '/print-shopee-tracking',
            },
            {
                key: 'print-lazada',
                label: 'Lazada',
                link: '/print-lazada-tracking',
            },
        ],
    },
    {
        key: 'cargo',
        label: 'คาร์โก้',
        icon: <ShopFilled />,
        children: [
            {
                key: 'search-cargo-tracking',
                label: 'ค้นหาแทร็ก',
                link: '/search-cargo-tracking',
            },
        ],
    },
    {
        key: 'log-out',
        label: 'ออกจากระบบ',
        icon: <LogoutOutlined />,
        link: '/',
    },
]

export const NavigationBar = () => {
    const location = useLocation()
    const selectedKeys = [location.pathname]
    const defaultOpenKeys = getDefaultOpenKeys(items, location.pathname)
    const [collapsed, setCollapsed] = useState(false)

    function getDefaultOpenKeys(menuItems: MenuItem[], pathname: string): string[] {
        for (const item of menuItems) {
            if (item.link === pathname) {
                return [item.key]
            }
            if (item.children) {
                const childDefaultOpenKeys = getDefaultOpenKeys(item.children, pathname)
                if (childDefaultOpenKeys.length > 0) {
                    return [item.key, ...childDefaultOpenKeys]
                }
            }
        }
        return []
    }

    const renderMenuItems = (menuItems: MenuItem[]): React.ReactNode => {
        return menuItems.map((menuItem) => {
            if (menuItem.children) {
                return (
                    <SubMenu key={menuItem.key} icon={menuItem.icon} title={menuItem.label}>
                        {renderMenuItems(menuItem.children)}
                    </SubMenu>
                )
            }

            return (
                <Item
                    key={menuItem.key}
                    icon={menuItem.icon}
                    className={
                        selectedKeys.includes(menuItem.link!) ? 'ant-menu-item-selected' : ''
                    }>
                    <Link to={menuItem.link!}>{menuItem.label}</Link>
                </Item>
            )
        })
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }
    return (
        <SiderContainer collapsed={collapsed}>
            <StyleButton style={{ textAlign: 'right' }} onClick={toggleCollapsed}>
                {collapsed ? (
                    <MenuUnfoldOutlined style={{ cursor: 'pointer' }} />
                ) : (
                    <MenuFoldOutlined style={{ cursor: 'pointer' }} />
                )}
            </StyleButton>
            <Menu
                mode="inline"
                theme="dark"
                selectedKeys={selectedKeys}
                defaultOpenKeys={defaultOpenKeys}>
                {renderMenuItems(items)}
            </Menu>
        </SiderContainer>
    )
}
const StyleButton = styled.div`
    text-align: right;
    font-size: 20px;
    color: white;
    margin: 10px;
`

const SiderContainer = styled(Sider)`
    min-height: 100vh;
`
