import { useContext } from 'react'
import { Spin } from 'antd'
import { BlockUiProvider, BlockUiContext } from './reducer'
import styled from 'styled-components'

function BlockUI() {
    const { state } = useContext(BlockUiContext)
    return (
        <>
            {state.isLoading && (
                <BlockUiContainer>
                    <Spin size={'large'} />
                </BlockUiContainer>
            )}
        </>
    )
}

export { BlockUI, BlockUiProvider, BlockUiContext }

const BlockUiContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(215, 220, 227, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
`

// const BlockUiMessage = styled.div`
//     background-color: white;
//     padding: 16px;
//     border-radius: 4px;
//     box-shadow: 0 1px 22px 3px #f2f2f2;
// `

// const BlockUiMessageContent = styled.div`
//     font-size: 16px;
//     font-weight: 500;
//     color: $dark_blue_100;
// `
