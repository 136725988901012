import axios from 'axios'
import { API_URL } from '../config'
import { throws } from 'assert'

export type CheckLocation = {
    latitude: number
    longitude: number
}

async function checkLocation(latitude: number, longitude: number): Promise<boolean> {
    const params: CheckLocation = {
        latitude: latitude,
        longitude: longitude,
    }
    return axios
        .get(`${API_URL}/portal/check-in-location`, { params })
        .then((resp) => resp.data)
        .catch((err) => {
            throw err.response.data
        })
}

async function checkIn(latitude: number, longitude: number): Promise<string> {
    return axios
        .post(`${API_URL}/portal/check-in-location`, {
            latitude: latitude,
            longitude: longitude,
        })
        .then((resp) => resp.data)
        .catch((err) => {
            throw err.response.data
        })
}

export type CheckInList = {
    check_in_history: {
        created_at: string
    }[]
}
async function checkInList(): Promise<CheckInList> {
    return axios.get(`${API_URL}/portal/user-check-in`).then((resp) => resp.data)
}

export { checkLocation, checkIn, checkInList }
