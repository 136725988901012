import axios from 'axios'
import { API_URL } from '../config'

export type ProductType = {
    count: number
    next: string
    previous: string
    results: ListItemProductType[]
}
export type ListItemProductType = {
    sku: string
    name: string
    description: string
    price: string
    created_at: string
    updated_at: string
    images: string[]
}

export type CargoType = {
    count: number
    next: string
    previous: string
    results: { data: ListItemCargoType[] }
}

export type ListItemCargoType = {
    date_arrival: string
    tracking_no: string
    note: string
    is_checked: boolean
}

async function product(): Promise<ProductType> {
    return axios
        .get(`${API_URL}/portal/products`)
        .then((resp) => resp.data)
        .catch((err) => {
            // implement error later
            console.log({ err })
        })
}

async function productEdit(suk: string): Promise<ProductType> {
    return axios
        .patch(`${API_URL}/portal/products/${suk}`)
        .then((resp) => resp.data)
        .catch((err) => {
            // implement error later
            console.log({ err })
        })
}
async function productDelete(id: string) {
    console.log('test' + id)
}

async function searchProduct(trackingNo: string | null, page: number | null): Promise<CargoType> {
    const params = {
        tracking_no: trackingNo,
        page: page,
    }
    return axios
        .get(`${API_URL}/portal/feed-cargo`, {
            params,
        })
        .then((resp) => resp.data)
        .catch((err) => {
            // implement error later
            console.log({ err })
        })
}

export { product, productEdit, productDelete, searchProduct }
