import React, { useEffect, useState } from 'react'
import { checkIn, CheckInList, checkInList } from 'services'
import { useLocationCheck } from 'hooks/useLocationCheck'
import { MainLayout } from 'layouts/mainLayout'
import { useNotification } from 'hooks/useNotification'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import moment from 'moment'

function CheckIn() {
    const [isLoading, setIsLoading] = useState(false)
    const { isLocationValid, getGeolocation, location, geolocationError } = useLocationCheck()
    const { openNotification } = useNotification()
    const [checkInHistory, setCheckInHistory] = useState<CheckInList | null>(null)
    const DATE_TINE_FORMAT = 'DD/MM/YYYY HH:mm:ss'
    const TODAY_DATE_TINE_FORMAT = 'HH:mm:ss'

    useEffect(() => {
        getGeolocation()
        getCheckInHistory().then()
    }, [])

    useEffect(() => {
        if (geolocationError) {
            openNotification(geolocationError, 'error')
        }
    }, [geolocationError])

    async function onSubmit() {
        try {
            setIsLoading(true)
            if (location) {
                const checkInResponse = await checkIn(location.latitude, location.longitude).catch(
                    (message) => {
                        openNotification(message, 'error')
                    }
                )
                if (checkInResponse) {
                    openNotification(checkInResponse, 'success')
                }
            }
        } finally {
            await getCheckInHistory()
            setIsLoading(false)
        }
    }

    async function getCheckInHistory() {
        const history = await checkInList()
        if (history) {
            setCheckInHistory(history)
        }
    }

    function isToday(date: string) {
        return moment(date).isSame(moment(), 'day')
    }

    return (
        <MainLayout>
            <Row>
                <Col span={24}>
                    <div>
                        <CheckInButton disabled={!isLocationValid || isLoading} onClick={onSubmit}>
                            {isLoading ? 'กำลังเช็คอิน...' : 'เช็คอิน'}
                        </CheckInButton>
                    </div>

                    <Hints>***เช็คอินช่วงเวลา เช้า ก่อนเวลา 9:00น. และเย็น หลังเวลา 18:00น.</Hints>
                </Col>
                <Col span={24}>
                    <h3>เวลาเช็คอิน</h3>
                    <ul>
                        {checkInHistory &&
                            checkInHistory.check_in_history.map((item, index) => {
                                const today = isToday(item.created_at)
                                return (
                                    <li key={index}>
                                        <p>
                                            {today && 'วันนี้ '}
                                            {moment(item.created_at).format(
                                                today ? TODAY_DATE_TINE_FORMAT : DATE_TINE_FORMAT
                                            )}
                                        </p>
                                    </li>
                                )
                            })}
                    </ul>
                </Col>
            </Row>
        </MainLayout>
    )
}

export default CheckIn

const CheckInButton = styled.button`
    height: 64px;
    padding: 0 32px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 1px 22px 3px #f2f2f2;
    font-size: 18px;
    background-color: #07504a;
    color: #fff;

    &:disabled {
        background-color: #eaeaea;
        color: #000;
    }
`

const Hints = styled.p`
    color: #6b6b6b;
`
