import { createBrowserRouter } from 'react-router-dom'
import LoginPage from 'pages/login'
import ProductManager from 'pages/product-manager'
import CheckIn from 'pages/check-in'
import PrintLazadaTracking from 'pages/print-lazada-tracking'
import PrintShopeeTracking from 'pages/print-shopee-tracking'
import SearchCargoTracking from 'pages/search-cargo-tracking'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/product-manager',
        element: <ProductManager />,
    },
    {
        path: '/check-in',
        element: <CheckIn />,
    },
    {
        path: '/print-shopee-tracking',
        element: <PrintShopeeTracking />,
    },
    {
        path: '/print-lazada-tracking',
        element: <PrintLazadaTracking />,
    },
    {
        path: '/search-cargo-tracking',
        element: <SearchCargoTracking />,
    },
])
