import React from 'react'
import styled from 'styled-components'
import { Layout } from 'antd'
import { NavigationBar } from 'components'
import { MainHeader } from 'layouts/mainHeader'

const { Content } = Layout

type MainLayoutProps = {
    children: React.ReactNode
    pageName?: string
    logo?: string
}

export function MainLayout({ children, pageName, logo }: MainLayoutProps) {
    return (
        <Container>
            <NavigationBar />
            <MainLayoutBody>
                <MainHeader pageName={pageName} logo={logo} />
                <MainLayoutContent>{children}</MainLayoutContent>
            </MainLayoutBody>
        </Container>
    )
}

const Container = styled(Layout)`
    min-height: 100vh;
    background: #f2f2f2;
`

const MainLayoutBody = styled(Content)`
    background: #f2f2f2;
`

const MainLayoutContent = styled.div`
    margin: 16px;
    padding: 16px;
    overflow-x: scroll;
    background: #fff;
    height: calc(100vh - 64px - 32px);

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
