import React, { createContext, useReducer } from 'react'
interface BlockUiState {
    isLoading: boolean
}
interface BlockUiAction {
    type: 'SET'
    payload: BlockUiState
}

const initialState: BlockUiState = {
    isLoading: false,
}
export const blockUiReducer = (state: BlockUiState, action: BlockUiAction) => {
    switch (action.type) {
        case 'SET':
            state.isLoading = action.payload.isLoading
            return { ...state }
        default:
            return state
    }
}

export const BlockUiContext = createContext<{
    state: BlockUiState
    dispatch: React.Dispatch<any>
}>({
    state: initialState,
    dispatch: () => null,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function BlockUiProvider({ children }: any) {
    const [state, dispatch] = useReducer(blockUiReducer, initialState)
    return <BlockUiContext.Provider value={{ state, dispatch }}>{children}</BlockUiContext.Provider>
}
