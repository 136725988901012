import { useEffect, useState } from 'react'
import { MainLayout } from 'layouts/mainLayout'
import { Row, Col, Upload, Button, Divider } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { FilePdfOutlined, DownloadOutlined } from '@ant-design/icons'
import { uploadPdf, listPdf, downloadPdf } from 'services'
import { useBlockUiHandler } from 'hooks/useBlockUiHandler'
import { useNotification } from 'hooks/useNotification'
import _ from 'lodash'

function PrintShopeeTracking() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [pdfFiles, setPdfFiles] = useState<any[]>([])
    const { blockUi, unblockUi } = useBlockUiHandler()
    const { openNotification } = useNotification()
    const [removeFiles, setRemoveFiles] = useState([])

    function loadListPdf() {
        listPdf('shopee').then((resp) => {
            setPdfFiles(
                resp.pdf_files.map((pdf: string) => ({
                    uid: pdf,
                    name: pdf,
                    url: pdf,
                    status: 'done',
                }))
            )
        })
    }
    useEffect(() => {
        loadListPdf()
    }, [])
    function handleFileChanged(files: any) {
        setPdfFiles(files.fileList)
    }

    function getUnUploadFile() {
        return pdfFiles.filter((file) => file.status !== 'done' && file.status !== 'removed')
    }
    async function uploadFile() {
        const removedExistingFiles = getUnUploadFile()
        if (removedExistingFiles.length === 0) {
            openNotification('ไม่มีไฟล์ PDF', 'info')
            return
        }
        blockUi()
        openNotification('กำลังอัพโหลดไฟล์ PDF', 'info')
        const fileToUpload = _.chunk(removedExistingFiles, 5)
        for (const files of fileToUpload) {
            const formData = new FormData()
            files.forEach((file) => {
                formData.append('pdf', file.originFileObj)
            })
            await uploadPdf('shopee', formData)
        }

        unblockUi()
        openNotification('อัพโหลดไฟล์เสร็จแล้ว', 'success')
        loadListPdf()
    }

    async function downloadFile() {
        blockUi()
        openNotification('กำลังดาวน์โหลดไฟล์ PDF', 'info')
        downloadPdf('shopee', removeFiles)
            .then((resp) => {
                const blob = new Blob([resp], { type: 'application/zip' })
                const link = document.createElement('a')

                // create a blobURI pointing to our Blob
                link.href = URL.createObjectURL(blob)
                link.download = 'output.zip'

                // some browser needs the anchor to be in the doc
                document.body.append(link)
                link.click()
                link.remove()
                setPdfFiles([])

                // in case the Blob uses a lot of memory
                setTimeout(() => URL.revokeObjectURL(link.href), 7000)
            })
            .finally(() => unblockUi())
    }

    function removeFile(fileInfo: UploadFile) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        removeFiles.push(fileInfo.name)
        setRemoveFiles(removeFiles)
    }
    return (
        <MainLayout pageName="ปริ๊นแทร็ก SHOPEE">
            <Row justify={'end'} gutter={[16, 16]}>
                <Col>
                    <Button
                        type="primary"
                        onClick={uploadFile}
                        disabled={pdfFiles.length === 0}
                        icon={<FilePdfOutlined />}
                        size={'middle'}>
                        อัพโหลด
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={downloadFile}
                        disabled={getUnUploadFile().length !== 0 || pdfFiles.length === 0}
                        icon={<DownloadOutlined />}
                        size={'middle'}>
                        ดาวน์โหลด
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Row justify={'center'}>
                <Col md={18} sm={25}>
                    <Upload
                        fileList={pdfFiles}
                        beforeUpload={() => false} // to prevent auto upload
                        accept=".pdf"
                        multiple
                        name="pdfFiles"
                        onPreview={() => false}
                        onRemove={removeFile}
                        onChange={handleFileChanged}>
                        <Button icon={<FilePdfOutlined />}>เลือกไฟล์ PDF</Button>
                    </Upload>
                </Col>
            </Row>
        </MainLayout>
    )
}

export default PrintShopeeTracking
