import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import styled from 'styled-components'

type PortalTableProp = {
    data: object[] | undefined
    columns: ColumnsType<any>
}
export const PortalTable = (props: PortalTableProp) => {
    console.log(props)
    return (
        <StyleTable>
            <Table
                columns={props.columns}
                dataSource={props.data}
                pagination={false}
                rowKey={'id'}
                size="middle"
            />
        </StyleTable>
    )
}
const StyleTable = styled.div`
    .ant-table-thead .ant-table-cell {
        background-color: #315a9c;
        color: #fff;
        &:before {
            background-color: #5170df;
        }
    }
    .ant-table-tbody {
        background: white;
    }
`
