import { useContext } from 'react'
import { BlockUiContext } from 'components/blockui'

export function useBlockUiHandler() {
    const blockUiContext = useContext(BlockUiContext)
    const blockUi = () => {
        blockUiContext.dispatch({
            type: 'SET',
            payload: {
                isLoading: true,
            },
        })
    }

    const unblockUi = () => {
        blockUiContext.dispatch({
            type: 'SET',
            payload: {
                isLoading: false,
            },
        })
    }

    return { blockUi, unblockUi }
}
