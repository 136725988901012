import { Button, Form, Input, Row, Col } from 'antd'
import { useLoginHandler } from 'hooks/useLoginHandler'
import styled from 'styled-components'

const LoginPage = () => {
    const { form, onFinish } = useLoginHandler()
    return (
        <Container>
            <Row justify={'center'}>
                <LoginContent md={6} xs={18}>
                    <Logo src="/images/logo.jpeg" />
                    <Title>Smart EV Accessory Portal</Title>
                    <Form
                        name="login_form"
                        autoComplete="off"
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}>
                        <Form.Item label="Username" name="username">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Password" name="password">
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                เข้าสู่ระบบ
                            </Button>
                        </Form.Item>
                    </Form>
                </LoginContent>
            </Row>
        </Container>
    )
}

export default LoginPage

const Container = styled.div`
    height: 100vh;
    display: grid;
    align-content: center;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/images/electric-cars-parking-lot-charging.jpg');
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0.2; /* Adjust the opacity value (0.5 in this example) */
    }
`
const LoginContent = styled(Col)`
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 24px;
`
const Logo = styled.img`
    height: 100px;
    width: 100px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
`

const Title = styled.h1`
    text-align: center;
`
