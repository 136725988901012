import { Layout, Row } from 'antd'
import styled from 'styled-components'

type MainHeaderProps = {
    pageName?: string
    logo?: string
}
export const MainHeader = ({ pageName, logo }: MainHeaderProps) => {
    return (
        <HeaderContainer>
            <Row>
                {logo && <Logo src={logo} />}
                <Title>{pageName}</Title>
            </Row>
        </HeaderContainer>
    )
}
const HeaderContainer = styled(Layout.Header)`
    background: #fff;
    box-shadow: 0 1px 22px 3px #f2f2f2;
    overflow: hidden;
`

const Title = styled.div`
    font-size: 16px;
    font-weight: 800;
`

const Logo = styled.img`
    height: 40px;
    width: 150px;
    padding-right: 8px;
    margin: auto 0;
`
