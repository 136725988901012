import { login, JwtToken, setAuthorizationHeader } from '../services'
import { Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useNotification } from './useNotification'
import { useBlockUiHandler } from './useBlockUiHandler'

export function useLoginHandler() {
    const [form] = Form.useForm()
    const router = useNavigate()
    const { blockUi, unblockUi } = useBlockUiHandler()

    async function onFinish() {
        blockUi()
        const username = form.getFieldValue('username')
        const password = form.getFieldValue('password')
        const response = await login(username, password).catch(() => {
            useNotification().openNotification('เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง', 'error')
        })
        if (response) {
            const token: JwtToken = response
            localStorage.setItem('token', token.refresh)
            setAuthorizationHeader(token.access)
            useNotification().openNotification('Login Success', 'success')
            router('/check-in')
        }
        unblockUi()
    }

    return { form, onFinish }
}
