import { RouterProvider } from 'react-router-dom'
import 'services/axios_middleware'
import { router } from './router'
import { BlockUI, BlockUiProvider } from 'components/blockui'

function App() {
    return (
        <BlockUiProvider>
            <BlockUI />
            <RouterProvider router={router} />
        </BlockUiProvider>
    )
}

export default App
