import { useEffect, useState } from 'react'
import { checkLocation } from 'services'

type FetchGeolocation = {
    latitude: number
    longitude: number
}

export function useLocationCheck() {
    const [location, setLocation] = useState<FetchGeolocation>()

    const [isLocationValid, setIsLocationValid] = useState<boolean>(false)
    const [geolocationError, setGeolocationError] = useState<string>('')

    useEffect(() => {
        getGeolocation()
    }, [])

    useEffect(() => {
        if (location) {
            checkLocation(location.latitude, location.longitude)
                .then(() => {
                    setIsLocationValid(true)
                    setGeolocationError('')
                })
                .catch((error) => {
                    setGeolocationError(error)
                })
        }
    }, [location])

    function getGeolocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    })
                    setGeolocationError('')
                },
                () => setGeolocationError('กรุณาอนุญาตเข้าถึงตำแหน่งที่ตั้ง')
            )
        } else {
            throw 'อุปกรณ์ของคุณไม่รองรับการเข้าถึงตำแหน่งที่ตั้ง'
        }
    }

    return { getGeolocation, location, isLocationValid, geolocationError }
}
