import { Form, Input, Modal } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { FormInstance } from 'antd/lib/form/hooks/useForm'

interface ProductManagerModalProps {
    isModalOpen: boolean
    formSubmit: () => void
    form: FormInstance<any> | undefined
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}
function ProductManagerModal(props: ProductManagerModalProps) {
    function hideModal() {
        props.setIsModalOpen(false)
    }
    return (
        <Modal
            title={<div style={{ textTransform: 'capitalize' }}>เพิ่มข้อมูล</div>}
            width={800}
            open={props.isModalOpen}
            onOk={props.formSubmit}
            onCancel={hideModal}
            cancelText={'ยกเลิก'}
            okText={'ตกลง'}>
            <Form
                name="productManager"
                form={props.form}
                autoComplete="off"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}>
                <Form.Item label="รูป" name="activity">
                    <Input placeholder="please input activity..." />
                </Form.Item>
                <Form.Item label="รหัสสินค้า" name="sku">
                    <Input placeholder="กรุณากรอกรหัสสินค้า" />
                </Form.Item>
                <Form.Item label="ชื่อสินค้า" name="name">
                    <Input placeholder="กรุณากรอกชื่อสินค้า" />
                </Form.Item>
                <Form.Item label="รายละเอียด" name="description">
                    <Input placeholder="กรุณากรอกรายละเอียดชื่อสินค้า" />
                </Form.Item>
                <Form.Item label="ราคา" name="description">
                    <Input placeholder="กรุณากรอกราคา่" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ProductManagerModal
